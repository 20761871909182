<template>
  <v-container id="registration" tag="section">
    <base-material-wizard
      v-model="tab"
      :available-steps="availableSteps"
      :items="tabs"
      class="mx-auto"
      @click:next="next"
      @click:prev="tab--"
    >
      <v-tab-item class="pb-12">
        <form action data-vv-scope="about">
          <div
            class="text-center display-1 font-weight-light mb-6"
          >Let us start with basic information</div>

          <v-row class="mx-auto" justify="space-around" style="max-width: 500px;">
            <v-col cols="auto" class="text-center">
              <input ref="file" type="file" class="d-none" @change="onChange" />
              <v-card
                :class="formReg.image ? 'success--text' : 'grey--text'"
                class="mx-auto mt-0 d-inline-flex v-card--account"
                outlined
                height="106"
                width="106"
                @click="$refs.file.click()"
              >
                <v-img v-if="formReg.image" :src="formReg.image" height="100%" width="100%" />
                <v-icon v-else class="mx-auto" size="96">mdi-account</v-icon>
              </v-card>

              <div class="font-weight-bold grey--text">CHOOSE PICTURE</div>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="formReg.firstname"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('about.first')"
                color="secondary"
                data-vv-name="firstname"
                label="First name*"
                prepend-icon="mdi-face"
                validate-on-blur
              />

              <v-text-field
                v-model="formReg.lastname"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('about.last')"
                color="secondary"
                data-vv-name="last"
                label="Last Name*"
                prepend-icon="mdi-account"
                validate-on-blur
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="formReg.email"
                v-validate="'required|email'"
                :error-messages="errors.collect('about.email')"
                color="secondary"
                data-vv-name="email"
                label="Email*"
                autocomplete="off"
                prepend-icon="mdi-email"
                validate-on-blur
                type="email"
              />
              <v-text-field
                v-model="formReg.password"
                v-validate="'required|min:6|max:35|'"
                :error-messages="errors.collect('about.password')"
                color="secondary"
                data-vv-name="password"
                label="Password*"
                prepend-icon="mdi-lock-outline"
                validate-on-blur
                id="password"
                autocomplete="off"
                type="password"
                required
                placeholder="Create password"
              />
            </v-col>
          </v-row>
          <v-snackbar
            v-model="retSnackbar.show"
            :bottom="retSnackbar.y === 'bottom'"
            :color="retSnackbar.color"
            :left="retSnackbar.x === 'left'"
            :multi-line="retSnackbar.mode === 'multi-line'"
            :right="retSnackbar.x === 'right'"
            :timeout="retSnackbar.timeout"
            :top="retSnackbar.y === 'bottom'"
            :vertical="retSnackbar.mode === 'vertical'"
          >
            {{ retSnackbar.text }}
            <v-btn dark text @click="retSnackbar.show = false">Close</v-btn>
          </v-snackbar>
        </form>
      </v-tab-item>

      <v-tab-item class="pb-12">
        <form action data-vv-scope="interest">
          <v-responsive class="mx-auto" max-width="500">
            <div
              class="text-center display-1 grey--text font-weight-light mb-6"
            >What is your favourite excercise? (checkboxes)</div>

            <input
              v-validate="'required'"
              :value="stringInterest"
              type="hidden"
              data-vv-name="type"
            />

            <v-item-group v-model="formReg.interest" class="row" multiple>
              <v-item v-for="(interest, i) in interests" :key="i">
                <template v-slot="{ active, toggle }">
                  <v-col class="text-center" cols="4">
                    <v-card
                      :class="active ? 'success--text' : 'grey--text'"
                      class="mb-6 mx-auto pa-10 d-inline-block v-card--account"
                      outlined
                      @click="toggle"
                    >
                      <v-icon large v-text="interest.icon" />
                    </v-card>

                    <div class="text-uppercase subtitle-2 text--primary" v-text="interest.type" />
                  </v-col>
                </template>
              </v-item>
            </v-item-group>
          </v-responsive>
        </form>
      </v-tab-item>
      <v-tab-item class="pb-12">
        <v-responsive class="mx-auto" max-width="500">
          <div class="text-center display-1 grey--text font-weight-light mb-6">
            Congratulation - you are all set!
            <p>Click her to start recording your excersises</p>
          </div>
        </v-responsive>
      </v-tab-item>
    </base-material-wizard>
  </v-container>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { runPost } from "@/views/dashboard/components/core/orarest";

const initUser = {
  // marketid: 0, // process.env.VUE_APP_MARKETID,
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  image: null,
  interest: []
};
export default {
  name: "Registration",
  metaInfo: {
    title: "Registration",
    titleTemplate: "Login Gothar Exercise",
    description: "Registration Gothar Exercise."
  },
  data() {
    return {
      formReg: Object.assign({}, initUser),
      interests: [
        {
          icon: "mdi-pencil",
          type: "Crossfit"
        },
        {
          icon: "mdi-code-tags",
          type: "Running"
        },
        {
          icon: "mdi-laptop",
          type: "Strength"
        }
      ],
      tab: 0,
      tabs: ["About", "Interests", "Completed"],
      retSnackbar: {
        show: false,
        color: "error",
        timeout: 8000,
        text: "Something went wrong",
        mode: "",
        x: null,
        y: "bottom"
      }
    };
  },
  computed: {
    stringInterest() {
      return this.formReg.interest.join(",");
    },
    scope() {
      if (this.tab === 0) return "about";
      // else if (this.tab === 1) return 'account'
      return "interest";
    },
    availableSteps() {
      const steps = [0];

      if (
        this.formReg.firstname &&
        this.formReg.lastname &&
        this.formReg.email &&
        this.formReg.password
      )
        steps.push(1);

      if (this.stringInterest && steps.includes(1)) steps.push(2);

      if (
        this.address &&
        this.street &&
        this.city &&
        this.state &&
        steps.includes(2)
      )
        steps.push(3);

      return steps;
    }
  },

  methods: {
    next() {
      this.validateForm(this.scope).then(item => {
        if (!item) return;
        if (this.tab === this.tabs.length - 2) {
          // alert('Form finished')
          this.onSubmit();
        } else this.tab++;
      });
    },
    onChange(val) {
      const value = val.target.files[0];

      if (!value) return (this.formReg.image = null);

      this.formReg.image = URL.createObjectURL(value);
    },
    validateForm(scope) {
      return this.$validator.validateAll(scope);
    },
    async onSubmit() {
      var sendParams = {};
      sendParams.formReg = this.formReg;
      var response = await runPost(sendParams);
      console.info("gex rRE json: ", response);
      if (response.status == 200) {
        this.tab++;
        localStorage.setItem('gextoken', response.gextoken ) // store the token in localstorage
   
      } else {
        this.tab = 0;
        this.retSnackbar.text = response.retmsg;
        this.retSnackbar.show = true;
      }
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  }
};
</script>

<style lang="sass">
.v-card.v-card.v-card--account
  border-color: currentColor
  border-width: 4px

  .v-icon
    color: inherit

  .v-card--account,
  .v-card--account:before
    border-radius: 50%
</style>
